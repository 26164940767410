#footer{
    background-color: black !important;
}

.footer-text{
    color: white;
}
.copyright{
    font-size: 10px;
}

.my-credit {
    color: grey !important; 
    text-decoration: none;
    background-color: transparent;
}
