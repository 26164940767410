$bg-primary: #59bdee;
$btn-hover: #000021;
$btn-hover-text: white;


.main-img {
    width: 100%;
    background-image: url('../../Images/landingbg_nof.png');
    height: 35vw;
    width: 100%;
    background-size: cover;
}

.centered {
    position: relative;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -343%);
}

.centered-below {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-56%, -50%);
}


.centered-btn {
    position: absolute;
    top: 200%;
    left: 68%;
    transform: translate(-50%, -50%);
}

.company-tag {
    font-size: 20px;
    color: white;
    font-family: 'Roboto', sans-serif;
}

.company-name {
    width: 45vw;
}

.company-btn {
    font-weight: bold;
    border: none;
    color: #000021;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    font-size: 31px;
    width: 360px;
    border-radius: 8px;
    background-color: white;
    margin-left: 58vw;

}

.company-btn:hover {
    background-color: $btn-hover;
    color: $btn-hover-text;
}


@media only screen and (max-width:768px) {

    .company-name {
        width: 87vw;
        height: 74vw;
    }

    .company-tag {
        display: none;
    }

    .company-btn {
        margin-left: 4vw;
        margin-top: 2vw;
        width: 95vw;
    }
    
    .main-img {
        background-image: none;
    }

    hr {
        display: none;
    }


}