.product-body{
    background-image: url('../../Images/flour_spoon_flo_table.jpg');
}
.card-center {
  margin-left: 7.5rem !important;
}
.product-title{
    color: white;
}

.top-title {
  font-size: 2em;
  font-family: "Finger Paint", cursive;
}

.home-link {
   color: white;
}

@media only screen and (max-width: 768px) {

  .card-center{
    margin-left: 1.1rem !important;
  }

  .product-title {
    margin-left: -21vw;
    font-size: 20vw;

  }
 

  
}
