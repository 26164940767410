.header-info {
  color: #000021;
}
.top-link {
  color: black;
}


.video-link {
  color: rgb(41, 32, 3);
}

.black-owned {
  font-size: 13px;
}

@media only screen and (max-width: 1189px) {

  .facebook{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header-info {
    display: none;
  }

  .facebook{
    display: none;
  }

  hr {
    display: none;
  }
}
