.total-price {
    font-size: 25px;
}

.side-note-price {
    font-size: x-small;
    color: blue;
}

.bottom-note {
    font-size: 11px;
}