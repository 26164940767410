$bg-primary: #59bdee;
$btn-hover: white;
$btn-hover-text: black;

.item-info{
    background-image: url('../../Images/flour_spoon_flo_table.jpg');
    background-size: cover;
}

.item-title{
    color: whitesmoke;
}

.item-ingredients{
    color: whitesmoke;
}

.order-btn {
    font-weight: bold;
    border: 2px 2px 2px 2px 2px#FFFFFF;
    color: white;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    font-size: 31px;
    width: 360px;
    border-radius: 8px;
    background-color: rgb(10, 7, 7);
   
}

.order-btn:hover {
    background-color: $btn-hover;
    color: $btn-hover-text;
}


@media only screen and (max-width:991px) {
   
    .order-btn {
        width: 324px;   
    }

    #second {
        display: none; 
        order: 2!important;
       
       }
    #first {
        order: 1!important;
        margin-left: 0rem !important;
     }
   
    
}