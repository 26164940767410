@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
$font-family: 'Finger Paint', cursive;
@import '~video-react/styles/scss/video-react';

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    font-family: $font-family;
    background-image: url('../src/Images/brown_wp.png');

}

.navbar-light .navbar-nav .nav-link {
    color: white;
    font-size: 20px;

}