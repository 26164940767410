$text: center;
$line: 1.2;
$bg-primary: #59bdee;

/* centered columns styles */
.row-centered {
  text-align: center;
}
.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

.btn-primary {
  color: $bg-primary;
  background-color: white;
  border-color: $bg-primary;
}

@media only screen and (max-width: 768px) {
   
  .flo-image {
    width: 409px;
    margin-left: -36px;
   }
}
