$btn-hover: #000021;
$btn-hover-text: white;

.about-description {
    background-image: url('../../Images/flour_spoon_flo_table.jpg');
    background-size: cover;
    margin-top: 50px;
}

.flourlogo {
    width: 36vw;
}

.about-text {
    color: white;

}

.about-title {
    font-size: 4vw;
    color: white;
}

.about-btn {
    font-weight: bold;
    border: none;
    color: #000021;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    font-size: 26px;
    width: 242px;
    border-radius: 8px;
    background-color: white;
}

.about-btn:hover {
    background-color: $btn-hover;
    color: $btn-hover-text;
}

@media only screen and (max-width:768px) {

    .about-title {
        font-size: 10vw;
        color: white;
    }

    .about-description {

        margin-top: 273px;
    }
}